<template>
  <div class="student-messages-page">
    <v-row justify="center" no-gutters>
      <v-col md="5">
          <intract-smart-list
            class="mt-2"
            paginated
            :endpoint="reportCardEndpoint"
            :filter-fields="currentUser.is_student ? null : filterFields"
            @updateGetItemsFunction="(f) => (getItemsFunction = f)"
          >
            <template v-slot:list-item="{ item:  reportCardGroup }">
              <v-card class="mx-4 my-2" elevation="2" :to="currentUser.is_student ? reportCardGroup.report_card_id ? { name: 'ViewReportCard', params: { report_id: reportCardGroup.report_card_id }} : null : { name: 'ViewReportCards', params: { group_id : reportCardGroup.id }}">
                <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold primary--text">
                        {{ reportCardGroup.title }} - {{ reportCardGroup.room.sections }}
                  </v-list-item-title>
                  <v-list-item-title v-if="!currentUser.is_student">{{ reportCardGroup.student_count }} Students</v-list-item-title>
                  <v-list-item-title>{{ reportCardGroup.creator.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>{{  moment(reportCardGroup.date).format("DD MMM YYYY") }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                    <v-list-item-action-text v-if="reportCardGroup.is_draft">
                      <v-chip color="green lighten-4" small
                        >Draft</v-chip
                      >
                    </v-list-item-action-text>
                      <v-icon v-else>mdi-arrow-right</v-icon>

                  </v-list-item-action>     
              </v-list-item>
              </v-card>            
              <!-- <v-divider class="mx-4"></v-divider> -->
          </template>
        </intract-smart-list>
          <transition name="slide-y-reverse-transition">
          <v-btn
            class="non-focused"
            color="primary"
            dark
            v-if="!currentUser.is_student"
            right
            bottom
            fixed
            rounded
            fab
            @click="listSheetOptionsVisible = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </transition>
      </v-col>
    </v-row>
    <intract-list-sheet
      title="Settings"
      :visible="listSheetOptionsVisible"
      :list="listSheetOptions"
      @close="listSheetOptionsVisible = false"
    />

    <generate-report-card-sheet
      :visible="showGenerateReportCardSheet"
      v-if="!currentUser.is_student"
      :rooms="getUserRooms()"
      @close="showGenerateReportCardSheet = false"
    ></generate-report-card-sheet>


  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import moment from "moment";
import IntractSmartList from "@components/generics/IntractSmartList";
import { mapGetters } from "vuex";
import GenerateReportCardSheet from "@components/report_card/GenerateReportCardSheet";
import IntractListSheet from "@components/generics/IntractListSheet";


export default {
  name: "ReportCardDashboard",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractSmartList,
    IntractListSheet,
    GenerateReportCardSheet,
  },
  data() {
    return {
      getItemsFunction: null,
      moment: moment,
      showGenerateReportCardSheet: false,
      listSheetOptions: [
        {
          icon: "mdi-play-protected-content",
          title: "Generate Report Cards",
          subtitle: "Generate and publish report cards for a particular classroom",
          action: () => (this.showGenerateReportCardSheet = true),
        },
        {
          icon: "mdi-book-open",
          title: "Manage Parameters",
          subtitle: "Manage parameters and parameter groups for evaluation of grades in report cards",
          action: () => (this.$router.push({ name: 'ReportCardSetup' })),
        },
      ],
      listSheetOptionsVisible: false,
    };
  },
  computed: {
    ...mapGetters(["allRooms"]),
    appBarOptions(){
      return this.currentUser.is_student ? null : {
        iconButton:{
          title: "Groups and Parameters",
          action: () => this.$router.push({ name: 'ReportCardSetup' }),
          icon: "mdi-cog",
        }
      }
    },
    reportCardEndpoint() {
      var url = this.endpoints.reportCardGroupViewSet;
      if(this.currentUser.is_student){
        return url
      }
      if(!this.currentUser.is_admin){
        return this.Helper.addUrlParams(url,[`room__class_teachers=${this.currentUser.id}`])
      } 
      // admin
      return url;
    },

    filterFields(){
      return{
        room: {
          model: null,
          items: this.getUserRooms(),
          multiple: true,
          label: "Filter by Classroom",
          itemValue: "id",
          itemText: "sections",
          param: "room__in",
        }
      }
    }
  },
  methods: {

    getUserRooms(){
      return this.currentUser.is_admin ? this.allRooms : this.allRooms.filter(room => room.class_teachers && room.class_teachers.map(ct => ct.id).includes(this.currentUser.id));
    },
  },
  created() {

  },
};
</script>